import axios from 'axios'; 
import Swal from 'sweetalert2';

axios.defaults.baseURL = window.location.protocol+"//"+window.location.host+"/api";
axios.defaults.headers.common['Authorization'] = "Bearer " +window.localStorage.getItem("jwt");

axios.interceptors.response.use((response) => {
	

	if(response?.data?.status == 'error') {
		
		Swal.fire({
			icon:'warning',
			title: response?.data?.message, 
			showCancelButton: false,
		}).then((result) => { 
			if (result.isConfirmed) {
				localStorage.clear();
				sessionStorage.clear();
				window.location.reload();
			}
		})  

		return;
		
	}

	if(response?.data?.is_logout) {
		
		Swal.fire({
			icon:'warning',
			title: 'Logout!',
			text: "Your account was logged in from another device/browser. If this is not you, please change your password.",
			showCancelButton: false,
		}).then((result) => { 
			// if (result.isConfirmed) {
				localStorage.clear();
				sessionStorage.clear();
				window.location.reload();
			// }
		})  
		
	}

	if(response?.data?.is_active)
		return response;
	else {
		Swal.fire({
			icon:'warning',
			title: 'Account is inactive!',
			text: "Please contact your administrator.  ",
			showCancelButton: false,
		}).then((result) => { 
			// if (result.isConfirmed) {
				localStorage.clear();
				sessionStorage.clear();
				window.location.reload();
			// }
		})  
	}

	
	// if (localStorage.getItem("user_data")) {
	// 	localStorage.clear();
	// 	sessionStorage.clear();
	// 	Swal.fire({
	// 		title: 'Error!',
	// 		text: "Unauthorized access.",
	// 		icon: 'warning', 
	// 	})
	// 	window.location.reload();
		
	// }
	// else {
	// 	window.location.href='#/pages/login';
	// }

	
	
}, (error) => {
	console.error(error)
	if(axios.isCancel(error)) {
		// if API request is cancelled, do nothing
	}
	else {
		console.log("ADAS")
		Swal.fire({
			title: 'Error!',
			text: "Ooops! Something went wrong. Please contact your administrator.",
			icon: 'warning', 
		})
		  throw error;
	}
  	
});

// axios.interceptors.response.use((response) => response , (error) => {
//   	Swal.fire({
// 		title: 'Error!',
// 		text: "Ooops! Something went wrong. Please contact your administrator.",
// 		icon: 'warning', 
// 	})
//   	throw error;
// });


export default axios;